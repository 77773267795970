import * as React from "react";

import "./src/styles/global.css";
import "./src/styles.scss";
import "./src/i18n";
import "./src/route";

export const onInitialClientRender = () => {
  // Inject updated Google Tag Manager script
  const script = document.createElement("script");
  script.innerHTML = `
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});
    var f=d.getElementsByTagName(s)[0],j=d.createElement(s);
    j.async=true;j.src="https://load.sst.vitadote.com/5ropkpxayf.js?"+i;
    f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','64pna83=aWQ9R1RNLUtQUVZQNEZR&page=2');
  `;
  document.head.appendChild(script);

  // Inject updated noscript iframe
  const noscript = document.createElement("noscript");
  const iframe = document.createElement("iframe");
  iframe.src = "https://load.sst.vitadote.com/ns.html?id=GTM-KPQVP4FQ";
  iframe.height = "0";
  iframe.width = "0";
  iframe.style.display = "none";
  iframe.style.visibility = "hidden";
  noscript.appendChild(iframe);
  document.body.appendChild(noscript);
};

export const onClientEntry = () => {
  const link = document.createElement("link");
  link.rel = "stylesheet";
  link.href = "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css";
  document.head.appendChild(link);
};
